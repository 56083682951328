.search-container {
    position: relative;
    display: flex;
    align-items: center;
    max-width: 650px;
    height: 40px;
    padding:15px;
    border-radius: 20px;
    background-color: #f2f2f2;
}

input[type="text"] {
    width: 100%;
    height: 100%;
    padding: 8px;
    border: none;
    font-size: 16px;
    color: #333;
    outline: none;
    background:transparent;
}

.search-icon {
    position: absolute;
    right: 0;
    font-size: 20px;
    color: #999;
    cursor: pointer;
}