

/* Navbar */



/* Header styling */
.header-navbar {
  background-color: #f8f9fa; /* Light background color */
}

.header-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.menu-icon {
  background: none;
  border: none;
  cursor: pointer;
}

.header-logo {
  height: 50px;
  /* Hanging effect */
  /* transform: rotate(-15deg);  */
  transition: transform 0.3s ease; /* Smooth transition */
}

.header-logo:hover {
  transform: rotate(0deg); /* Reset on hover */
}

.logo-wrapper {
  position: relative;
  padding-left: 50px;
}

.logo-container {
  position: relative;
  transform: rotate(10deg); /* Tilt the icon */
}

.header-search {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
}

.menu-dropdown {
  position: absolute;
  top: 50px;
  right: 0;
  background: white;
  border: 1px solid #ddd;
  padding: 10px;
  z-index: 999;
}

.menu-dropdown-list li {
  cursor: pointer;
  padding: 5px;
}

@media screen and (max-width: 768px) {
  .header-top {
    flex-direction: column;
    align-items: center;
  }
  .header-search {
    width: 100%;
  }
}



.header-navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000; /* Ensure navbar stays on top of other content */
}


.header-navbar {
    padding: 10px 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .header-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .menu-icon {
    border: none;
    background: none;
    cursor: pointer;
  }
  
  .header-logo {
    height: 30px;
  }
  
  .header-cart-link {
    margin-left: auto;
  }
  
  /* Dropdown Styles */
  .menu-dropdown {
    position: absolute;
    top: 50px; /* Adjust dropdown position */
    left: 10px; /* Align with menu icon */
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 200px; /* Set dropdown width */
    z-index: 1000;
  }
  
  .menu-dropdown-header {
    padding: 10px;
    font-weight: bold;
    background: #f8f8f8;
    border-bottom: 1px solid #ddd;
    display: flex; /* Make it a flex container */
    align-items: center; /* Vertically align items */
    gap: 10px; /* Add space between the elements */
  }
  
  .menu-dropdown-list {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .menu-dropdown-list li {
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }
  
  .menu-dropdown-list li a {
    text-decoration: none;
    color: #333;
  }
  
  .menu-dropdown-list li:hover {
    background: #f1f1f1;
  }
  
  /* Search Bar */
  .header-search-bar {
    margin-top: 10px;
  }
  
  .header-search-bar input {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px 10px;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .header-top {
      flex-direction: row;
    }
    .header-cart-link {
      margin-left: 10px;
    }
  }
  

  .header-cart-link {
    display: flex;
    align-items: center;
    color: #000;
    text-decoration: none;
    margin-left: auto;
  }
  
  .header-cart-link:hover {
    color: #007bff; /* Hover effect */
  }
  