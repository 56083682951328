.wrapper {
  text-align: center;
  background-color: white;
}

.icon {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 25px;
  background: #f3f5f9;
  margin: 20px auto;
}

.wrapper .row {
  justify-content: center;
}

.wrapper .feature {
  padding: 10px;
  border: 4px solid white;
}

.wrapper .feature h3 {
  font-size: 18px;
  font-weight: 500;
  width: fit-content;
  margin: 0 auto !important;
}

.wrapper p {
  font-size: 15px;
  margin-top: 20px;
}