/* CSS to fully darken the background */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.9); /* Full dark background */
    z-index: 10; /* Make sure it stays behind the modal content */
  }
  